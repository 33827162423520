import React from "react";
import theme from "theme";
import { Theme, Text, Em, Box, Hr, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Vibrant Ventures
			</title>
			<meta name={"description"} content={"Your choice for memorable moments"} />
			<meta property={"og:title"} content={"Vibrant Ventures"} />
			<meta property={"og:description"} content={"Your choice for memorable moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header2>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="linear-gradient(0deg,rgba(14, 5, 3, 0.91) 0%,rgba(0, 0, 0, 0.94) 100%),url(https://uploads.quarkly.io/65e71766009abe001f042fa9/images/1-2.jpg?v=2024-03-05T15:51:48.519Z)">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline1" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Service Features
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
					At Vibrant Ventures, we specialize in making your celebrations a sign of joy and togetherness. From intimate gatherings to grand conferences, our comprehensive services are tailored to every aspect of your event, ensuring an unforgettable experience.
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--lead">
					<Em>
						Imagine an event that speaks volumes about who you are, tells your story, and leaves an indelible impression on every attendee. At Vibrant Ventures, we strive to bring this vision to life. Collaborate with us to create not just an event, but a memorable chapter in your life journey.
					</Em>
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Event Categories
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Immerse yourself in the rhythm of your celebration with our bespoke arrangements for small concerts, perfect for music lovers seeking an intimate yet electrifying atmosphere.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Small Concerts
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							From milestone birthdays to casual gatherings, our personalized party planning ensures that each year is celebrated with a unique touch reflecting the guest of honor.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Birthday Parties
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Our professional and adaptable conference services promise not just a meeting of minds, but an engaging and inspiring exchange of ideas, facilitated by our meticulous organizational skills.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Conferences
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							With a love for romance and an eye for detail, we craft weddings that reflect the unique bond between couples. Our customized wedding planning services make your special day as charming and unique as your love story, filled with moments of joy, love, and celebration.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Weddings
							</Strong>
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Special Features
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							We work closely with you to develop a theme that not only matches your vision but also enhances the overall experience of your event.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Theme Development
							</Strong>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Our ability to transform spaces ensures that your venue becomes the perfect backdrop for your story, be it lighting, layout, or decor.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Custom Venue Setup
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Collaborating with a wide range of performers and gourmet caterers, we ensure that we hit the right notes on both fronts, guaranteeing your guests are well entertained and satisfied.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								Entertainment and Catering
							</Strong>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Features
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Creative flair
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Attention to detail
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Personalized approach
						</Text>
					</Box>
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px" />
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text4" />
			<Override slot="text5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});